// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
import ModalAddTeacherClass from "./modalAddTeacherClass/modalAddTeacherClass";
import ModalEditTeacherClass from "./modalEditTeacherClass/modalEditTeacherClass";
import React, { useEffect, useState } from "react";
import './modalTeacherClass.scss'
import Axios from "axios";
import CloseSVG from '../../icons/close';
import TableCstm from '../../TableCstm/TableCstm';
import Select from '../../select/select';
import Modal from "../../modal/modal";
import Alert from "../../alert/Alert";

const ModalTeacherClass = (props) => {
    //LIST 
    const [dataLists, setDataLists] = useState({
        teacherList: [],
        subsidiaryList: [],
        classdayList: [],
        levelList: {
            adultList: [],
            childrenList: []
        },
        unitList: [],
        typeclassList: [],
        classroomList: []
    });
    // Options for sorting the groups by different criteria
    const selectOptions = [
        { label: 'Salón', value: 'classroom' },
        { label: 'Maestro', value: 'teacher' },
        { label: 'Nivel', value: 'book' },
        { label: 'Horario', value: 'schedule' }
    ];
    const selectOptionsStatus = [
        { label: 'En curso', value: '1' },
        { label: 'Finalizado', value: '2' },
        { label: 'Eliminados', value: '3' },
    ];
    const [sortCriteria, setSortCriteria] = useState('classroom'); // State for tracking selected sorting criteria
    const [sortSubsidiaryCriteria, setSortSubsidiaryCriteria] = useState(''); // State for tracking selected sorting criteria
    const [sortStatusCriteria, setSortStatusCriteria] = useState(''); // State for tracking selected sorting criteria

    const [arrayTeacherClasses, setArrayTeacherClasses] = useState([])
    const [filteredArrayTeacherClasses, setFilteredArrayTeacherClass] = useState([]);


    const [teacherAddedFlag, setTeacherAddedFlag] = useState(0)
    const [selectedItemRow, setSelectedItemRow] = useState({})
    const [openModalAddTeacher, setOpenModalAddTeacher] = useState(false)
    const [openModalEditTeacher, setOpenModalEditTeacher] = useState(false)

    //Alert
    const [arrayAlerts, setArrayAlerts] = useState([])
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (props.open) {
            const fetchData = async () => {
                try {
                    // Ejecutar todas las promesas en paralelo
                    const [
                        teacherClassesResponse,
                        subsidiaryResponse
                    ] = await Promise.all([
                        GetAllTeacherClasses(),
                        GetSubsidiaryList(),
                    ]);

                    if (teacherClassesResponse.auth === false ||
                        subsidiaryResponse.auth === false) {
                        window.location.reload();
                        return;
                    }
                    // Actualizar todas las listas en un solo setDataLists
                    setArrayTeacherClasses(teacherClassesResponse.teacherClasses);
                    setDataLists(prevState => ({
                        ...prevState,
                        subsidiaryList: subsidiaryResponse.result.map(element => ({
                            value: element.id,
                            label: element.name,
                            classrooms: element.classrooms
                        })),
                    }));
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };

            const executeAsyncTasks = async () => {
                await fetchData();
            }
            executeAsyncTasks();
        }
    }, [props.open, teacherAddedFlag]);
    useEffect(() => {
        const preparedFilterTable = async () => {
            if (arrayTeacherClasses.length === 0) return; // Evitar ejecutar el filtro si no hay datos
            let valueSubsidiary = props.usr.subsidiary_id
                ; // Aquí defines el valor de la subsidiaria
            setSortSubsidiaryCriteria(valueSubsidiary);
            setSortStatusCriteria('1')
            applyFilters(valueSubsidiary, '1')
        };

        preparedFilterTable(); // Llamar a la función para filtrar
    }, [arrayTeacherClasses]); // Este effect depende de 'arrayTeacherClasses' para asegurarse de que se ejecuta después de que se actualiza
    const GetAllTeacherClasses = async () => {

        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeacherClasses/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetSubsidiaryList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getSubsidiaryList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    // Configuration for each sorting criteria
    const criteriaOptions = {
        classroom: {
            header: 'Classroom',
            columns: [
                { header: "Horario", accessor: "schedule", className: "tableCtype5 td" },
                { header: "Maestro", accessor: "teacher", className: "tableCtype5 td" },
                { header: "Nivel", accessor: "book", className: "tableCtype3 td" },
                { header: "Unidad", accessor: "unit", className: "tableCtype3 td" },
                { header: "Estudiantes", accessor: "students_qty", className: "tableCtype2 td" },
                { header: "Plantel", accessor: "subsidiary", className: "tableCtype4 td" },
            ],
            // Content for classroom section header
            sectionContent: (classroom) => (
                <div className='section-content'>
                    <h1>{classroom.name}</h1>
                    {classroom.id &&
                        <span>Capacidad: {classroom.capacity || 'Unknown'}</span>
                    }
                </div>
            ),
            // Data mapping for classroom sorting
            dataAccessor: (group) => ({
                id_group: group.id,
                id_filter: group.classroom.id,
                name: group.classroom.name || 'HORARIO DISPONIBLE',
                capacity: group.classroom.capacity || 'Unknown',
                schedule: group.full_dayclass || 'Unknown',
                teacher: group.teacher.name || 'Unknown',
                book: group.level.name || 'HORARIO',
                unit: group.level.unitName || 'DISPONIBLE',
                students_qty: group.students_qty !== null ? group.students_qty : '',
                subsidiary: group.subsidiary.name || 'Unknown',
            })
        },
        // Similar configuration for 'teacher' sorting
        teacher: {
            header: 'Teacher',
            columns: [
                { header: "Horario", accessor: "schedule", className: "tableCtype5 td" },
                { header: "Nivel", accessor: "book", className: "tableCtype3 td" },
                { header: "Unidad", accessor: "unit", className: "tableCtype3 td" },
                { header: "Estudiantes", accessor: "students_qty", className: "tableCtype2 td" },
                { header: "Salón", accessor: "classroom", className: "tableCtype3 td" },
                { header: "Plantel", accessor: "subsidiary", className: "tableCtype2 td" },
            ],
            sectionContent: (teacher) => (
                <div className='section-content'>
                    <h1>{teacher.name || 'Unknown'}</h1>
                    <span>Correo electrónico: {teacher.email || 'Unknown'}</span>
                    <br /><span>Telefono: {teacher.phoneNumber || 'Unknown'}</span>
                </div>
            ),
            dataAccessor: (group) => ({
                id_group: group.id,
                id_filter: group.teacher.id,
                name: group.teacher.name || 'Unknown',
                email: group.teacher.email || 'Unknown',
                phoneNumber: group.teacher.phoneNumber || 'Unknown',
                schedule: group.full_dayclass || 'Unknown',
                classroom: group.classroom.name || '',
                book: group.level.name || 'HORARIO',
                unit: group.level.unitName || 'DISPONIBLE',
                students_qty: group.students_qty !== null ? group.students_qty : '',
                subsidiary: group.subsidiary.name || 'Unknown',
            })
        },
        // Similar configuration for 'book' sorting
        book: {
            header: 'Book',
            columns: [
                { header: "Horario", accessor: "schedule", className: "tableCtype5 td" },
                { header: "Maestro", accessor: "teacher", className: "tableCtype5 td" },
                { header: "Unidad", accessor: "unit", className: "tableCtype3 td" },
                { header: "Estudiantes", accessor: "students_qty", className: "tableCtype2 td" },
                { header: "Salón", accessor: "classroom", className: "tableCtype3 td" },
                { header: "Plantel", accessor: "subsidiary", className: "tableCtype2 td" },
            ],
            sectionContent: (book) => (
                <div className='section-content'>
                    <h1>{book.book}</h1>
                </div>
            ),
            dataAccessor: (group) => (
                {
                    id_group: group.id,
                    id_filter: group.level.id,
                    book: group.level.name || 'Horario Disponible',
                    schedule: group.full_dayclass || 'Unknown',
                    classroom: group.classroom?.name || '',
                    teacher: group.teacher.name || 'Unknown',
                    unit: group.level?.unitName || 'HORARIO',
                    students_qty: group.students_qty !== null ? group.students_qty : 'DISPONIBLE',
                    subsidiary: group.subsidiary.name || 'Unknown',
                }
            )
        },
        // Similar configuration for 'schedule' sorting
        schedule: {
            header: 'Schedule',
            columns: [
                { header: "Maestro", accessor: "teacher", className: "tableCtype5 td" },
                { header: "Nivel", accessor: "book", className: "tableCtype3 td" },
                { header: "Unidad", accessor: "unit", className: "tableCtype3 td" },
                { header: "Estudiantes", accessor: "students_qty", className: "tableCtype2 td" },
                { header: "Salón", accessor: "classroom", className: "tableCtype3 td" },
                { header: "Plantel", accessor: "subsidiary", className: "tableCtype4 td" },
            ],
            sectionContent: (schedule) => (
                <div className='section-content'>
                    <h1>{schedule.schedule || 'Unknown'}</h1>
                </div>
            ),
            dataAccessor: (group) => ({
                id_group: group.id,
                id_filter: group.full_dayclass || '',
                schedule: group.full_dayclass || 'Unknown',
                classroom: group.classroom?.name || '',
                teacher: group.teacher?.name || 'Unknown',
                book: group.level?.name || 'HORARIO ',
                unit: group.level?.unitName || 'DISPONIBLE',
                students_qty: group.students_qty !== null ? group.students_qty : '',
                subsidiary: group.subsidiary?.name || 'Unknown',
                class_day: group.class_day || { name: '' },
                time: group.time || { start: '00:00', end: '00:00' }
            })
        }
    };

    // Function to sort the data alphabetically by the name field
    const sortData = (data) => {
        return data.sort((a, b) => {
            const aValue = a.name || '';
            const bValue = b.name || '';
            return aValue.localeCompare(bValue);
        });
    };

    // Handle sort change when user selects a different criteria
    const handleSortChange = (value) => {
        if (value) {  // Ensure 'value' is not undefined
            setSortCriteria(value);
        }
    };
    
    const handleSortSubsidiaryChange = (value) => {
        if (value) {
            setSortSubsidiaryCriteria(value);
            applyFilters(value, sortStatusCriteria);
        }
    };

    const handleSortStatusChange = (value) => {
        if (value) {
            setSortStatusCriteria(value);
            applyFilters(sortSubsidiaryCriteria, value);
        }
    };

    const applyFilters = (subsidiaryValue, statusValue) => {
        let filtrados = arrayTeacherClasses;

        // Filtrar por Sucursal
        if (subsidiaryValue) {
            filtrados = filtrados.filter(grupo => grupo.subsidiary.id === parseInt(subsidiaryValue));
        }

        // Filtrar por Estatus
        if (statusValue) {
            const statusNumber = parseInt(statusValue);
            filtrados = filtrados.filter(grupo => {
                if (statusNumber === 1) return grupo.is_finished === 0 && grupo.st === 0; // En curso
                if (statusNumber === 2) return grupo.is_finished === 1; // Finalizado
                if (statusNumber === 3) return grupo.st === 1; // Eliminado
                return true; // Si no coincide, no se filtra
            });
        }

        setFilteredArrayTeacherClass(filtrados);
    };
    // Assign a CSS class based on the book level for row styling
    const getBookClass = (fila) => {
        const bookColor = fila.book || '';
        switch (bookColor) {
            case "Nivel 1": return "level1book";
            case "Nivel 2": return "level2book";
            case "Nivel 3": return "level3book";
            case "Nivel 4": return "level4book";
            case "Nivel 5": return "level5book";
            case "Smile 1": return "k1book";
            case "Smile 2": return "k2book";
            case "Smile 3": return "k3book";
            case "Smile 4": return "k4book";
            case "Smile 5": return "k5book";
            case "Smile 6": return "k6book";
            case "Mega": return "k7book";
            // case "Kids 1": return "k1book";
            // case "kids 2": return "k2book";
            // case "kids 3": return "k3book";
            // case "kids 4": return "k4book";
            // case "kids 5": return "k5book";
            // case "kids 6": return "k6book";
            // case "Kids 7": return "k7book";
            default: return "available-schedule";
        }
    };

    // Group data based on the selected criteria
    const groupByCriteria = (data) => {
        const groupedData = data.reduce((acc, item) => {
            const key = item.id_filter; // Group by id
            if (!acc[key]) {
                acc[key] = { key, items: [] };
            }
            acc[key].items.push(item); // Add all related items to this id
            return acc;
        }, {});

        // Return grouped data
        return Object.keys(groupedData).map(key => ({
            key,
            items: groupedData[key].items // Pass the complete array here
        }));
    };


    const processGroups = () => {
        // Step 1: Map through GROUPS array and apply the dataAccessor based on the selected sorting criteria
        const data = filteredArrayTeacherClasses.map(group => criteriaOptions[sortCriteria].dataAccessor(group));
        // Step 2: Sort the data based on the schedule using the sortBySchedule function
        const sortedData = sortBySchedule(data);
        // Step 3: Group the sorted data by the selected criteria using groupByCriteria
        return groupByCriteria(sortedData);
    };

    const sortBySchedule = (data) => {
        // Check if the input is an array, handle error if not
        if (!Array.isArray(data)) {
            console.error('Data is not an array:', data);
            return []; // Return an empty array if there is an error
        }

        // Define the order of days for sorting
        const dayOrder = {
            "Lunes - Viernes": 1,
            "Sabado": 2,
            "Domingo": 3
        };

        // Helper function to convert time string (e.g., "14:30") to minutes
        const parseTime = (timeStr) => {
            const [hours, minutes] = timeStr.split(':').map(Number);
            return hours * 60 + minutes;
        };

        // Helper function to clean the day name (removes ' AM' or ' PM')
        const cleanDayName = (day) => {
            return day.replace(/\s+(AM|PM)/, ''); // Remove ' AM' or ' PM'
        };

        // Function to compare days based on the predefined order
        const compareDays = (aDay, bDay) => {
            const aValue = dayOrder[cleanDayName(aDay.trim())] || Infinity; // Assign Infinity if day is not found
            const bValue = dayOrder[cleanDayName(bDay.trim())] || Infinity;
            return aValue - bValue;
        };

        // Main sorting function: sort by day, then by start time, then by end time
        return data.sort((a, b) => {
            const aDay = a.class_day?.name || ''; // Get the day of class A
            const bDay = b.class_day?.name || ''; // Get the day of class B
            const aTimeStart = a.time?.start || '00:00'; // Get the start time of class A
            const bTimeStart = b.time?.start || '00:00'; // Get the start time of class B
            const aTimeEnd = a.time?.end || '00:00'; // Get the end time of class A
            const bTimeEnd = b.time?.end || '00:00'; // Get the end time of class B

            // First, compare by day (after cleaning the day name)
            const dayComparison = compareDays(aDay, bDay);
            if (dayComparison !== 0) {
                return dayComparison; // If days are different, return the comparison result
            }

            // If days are the same, compare by start time
            const timeComparison = parseTime(aTimeStart) - parseTime(bTimeStart);
            if (timeComparison !== 0) {
                return timeComparison; // If start times are different, return the comparison result
            }

            // If start times are the same, compare by end time
            return parseTime(aTimeEnd) - parseTime(bTimeEnd);
        });
    };
    //-------------------------------------------------------------------------------------------------------
    const handleCloseModalAddTeacherGroup = () => {
        setOpenModalAddTeacher(false)
    }
    const handleOpenModalAddTeacherGroup = () => {
        setOpenModalAddTeacher(true)
    }
    const handleCloseModalEditTeacherGroup = () => {
        setOpenModalEditTeacher(false)
    }
    const handleRowClick = (selectedRow) => {
        const idGroup = selectedRow.id_group;
        const selectedItem = filteredArrayTeacherClasses.find(item => item.id === idGroup);
        if (Object.keys(selectedItem).length !== 0) {
            if (props.usr.idPriv === parseInt(process.env.REACT_APP_API_SUPERADMIN_PRIVILEGE)) {
                setSelectedItemRow(selectedItem)
                setOpenModalEditTeacher(true)
            } else {
                if (props.usr.subsidiary_id === selectedItem.subsidiary.id) {
                    setSelectedItemRow(selectedItem)
                    setOpenModalEditTeacher(true)
                } else {
                    var thereIsAlert = 0
                    setIsVisible(true)
                    if (arrayAlerts.length <= 4) {
                        const objAlert = {
                            id: arrayAlerts.length + 1,
                            type: "danger",
                            message: 'Lo sentimos, no puede hacer edición de un grupo que no pertenezca a su sucursal'
                        }
                        if (arrayAlerts.length > 0) {
                            arrayAlerts.forEach((v) => {
                                if (v.message === objAlert.message) {
                                    thereIsAlert++
                                }
                            })
                            if (thereIsAlert === 0) {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        } else {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    }
                }
            }
        }
    };
    return (
        props.open &&
        <>
            <Modal isOpen={props.open} overlayClick={false}>
                <div className='list-groups-modal-teacher-class main-color'>
                    <section className='list-groups-header'>
                        <button onClick={props.onClose} className="close-button main-color">
                            <CloseSVG className="main-color" />
                        </button>
                        <div className='header-title'>
                            <h1>Listado de grupos</h1>
                            <div className='order-close'>
                                <div className='order-select'>
                                    <label>Estatus</label>
                                    <Select
                                        options={selectOptionsStatus}
                                        placeholder="Ordenar por..."
                                        onChange={(e) => handleSortStatusChange(e.target.value)}
                                        classN="main-color"
                                        value={sortStatusCriteria}
                                    />
                                </div>
                                <div className='order-select'>
                                    <label>Sucursal</label>
                                    <Select
                                        options={dataLists.subsidiaryList}
                                        placeholder="Ordenar por..."
                                        onChange={(e) => handleSortSubsidiaryChange(e.target.value)}
                                        classN="main-color"
                                        value={sortSubsidiaryCriteria}
                                    />
                                </div>
                                <div className='order-select'>
                                    <label>Orden</label>
                                    <Select
                                        options={selectOptions}
                                        placeholder="Ordenar por..."
                                        onChange={(e) => handleSortChange(e.target.value)}
                                        classN="main-color"
                                    />
                                </div>

                            </div>
                        </div>

                    </section>
                    {processGroups().map(group => (
                        <section key={group.key} className='classroom-contain'>
                            {criteriaOptions[sortCriteria].sectionContent(group.items[0])} {/* Mostramos solo la información de uno de los ítems */}
                            <TableCstm
                                columns={criteriaOptions[sortCriteria].columns}
                                data={group.items}
                                getRowClass={getBookClass}
                                onRowClick={handleRowClick} // Pasamos la función
                            />
                        </section>
                    ))}
                    <div className="modalFooter">
                        <div className="buttonContainer">
                            <button onClick={handleOpenModalAddTeacherGroup}><i className="material-icons">add</i></button>
                        </div>
                    </div>

                </div>
                <ModalAddTeacherClass
                    open={openModalAddTeacher}
                    onCloseButton={handleCloseModalAddTeacherGroup}
                    setTeacherAddedFlag={setTeacherAddedFlag}
                    usr={props.usr}
                />

                <ModalEditTeacherClass
                    open={openModalEditTeacher}
                    onCloseButton={handleCloseModalEditTeacherGroup}
                    editData={selectedItemRow}
                    setTeacherAddedFlag={setTeacherAddedFlag}
                    usr={props.usr}

                />
            </Modal>
            <div className="divAlert">
                <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisible} setIsVisibleModule={setIsVisible} />

            </div>
        </>
    );
};
export default ModalTeacherClass