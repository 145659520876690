import React, { useState, useEffect } from "react";
import Modal from "../../../modal/modal";
import Axios from "axios";
import "./modalEditTeacherClass.scss"
import InputAnimated from "../../../inputAnimated/inputAnimated";
import Alert from "../../../alert/Alert";
import Select from "../../../select/select";
import Button from "../../../button/button";
import TrashSVG from '../../../icons/trash';
import ConfirmModal from '../../../ConfirmModalV3/confirmModal'
const ModalEditTeacherClass = (props) => {
    const [idGroup, setIdGroup] = useState("")
    const [checkDayOff, setCheckDayOff] = useState(false)
    const [checkOnline, setCheckOnline] = useState(false)
    const [checkFinished, setCheckFinished] = useState(false)
    const [checkPart, setCheckPart] = useState("")
    const [teacher, setTeacher] = useState("")
    const [typeClassSelected, setTypeClassSelected] = useState("")
    const [subsidiarySelected, setSubsidiarySelected] = useState("")
    const [unitSelected, setUnitSelected] = useState("")
    const [levelSelected, setLevelSelected] = useState("")
    const [classroomSelected, setClassroomSelected] = useState("")
    const [dateStartSelected, setDateStartSelected] = useState("")
    const [dateFinishedSelected, setDateFinishedSelected] = useState("")
    const [selectedDays, setSelectedDays] = useState([]);
    const [timeStart, setTimeStart] = useState("")
    const [ageStart, setAgeStart] = useState("")
    const [ageEnd, setAgeEnd] = useState("")
    const [timeEnd, setTimeEnd] = useState("")
    const [observations, setObservations] = useState("")
    const [ageGroup, setAgeGroup] = useState("");
    const [studentLimit, setStudentLimit] = useState("")
    const [grupoTitle, setGrupoTitle] = useState('');
    const [stGroup, setStGroup] = useState('')

    const [arrayAlerts, setArrayAlerts] = useState([])

    var arrayEmptys = []

    /////requires
    const [teacherRequire, setTeacherRequire] = useState("")
    const [levelRequire, setLevelRequire] = useState("")
    const [unitRequire, setUnitRequire] = useState("")
    const [timeStartRequire, setTimeStartRequire] = useState("")
    const [timeEndRequire, setTimeEndRequire] = useState("")
    const [subsidiaryRequire, setSubsidiaryRequire] = useState("")
    const [classDayRequire, setClassDayRequire] = useState("")
    const [dateStartRequire, setDateStartRequire] = useState("")
    const [dateFinishedRequire, setDateFinishedRequire] = useState("")
    const [typeClassRequire, setTypeClassRequire] = useState("")
    const [classroomRequire, setClassroomRequire] = useState("")
    const [activeStudents, setActiveStundets] = useState("")
    const [ageStartRequire, setAgeStartRequire] = useState("")
    const [ageEndRequire, setAgeEndRequire] = useState("")
    const [agegroupRequire, setAgeGroupRequire] = useState("")
    //LIST 
    const [dataLists, setDataLists] = useState({
        teacherList: [],
        subsidiaryList: [],
        classdayList: [],
        levelList: {
            adultList: [],
            childrenList: []
        },
        unitList: [],
        typeclassList: [],
        classroomList: []
    });
    const [filterUnits, setFilterUnits] = useState([])
    const [filterTeachers, setFilterTeachers] = useState([])
    ///////disabled time inputs
    const [disableTimeStart, setDisableTimeStart] = useState(false);
    const [disableTimeEnd, setDisableTimeEnd] = useState(true);

    const [disableSubsidiary, setDisableSubsidiary] = useState(false)
    const [disableAll, setDisableAll] = useState(false);
    //////////////
    const [isVisible, setIsVisible] = useState(false);
    // ModalConfirm variables
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    // Define el estado para los handlers del modal
    const [modalHandlers, setModalHandlers] = useState({
        handleAccept: () => { },
        handleCancel: () => { },
    });
    const [modalDetailAction, setModalDetailAction] = useState('')
    const [modalTitleAction, setModalTitleAction] = useState('')
    useEffect(() => {
        if (props.open === true) {
            console.log(props)
            const getInitial = (str) => str.charAt(0).toUpperCase();
            const fetchData = async () => {
                try {
                    // Ejecutar todas las promesas en paralelo
                    const [
                        teacherResponse,
                        subsidiaryResponse,
                        classdayResponse,
                        levelResponse,
                        unitResponse,
                        typeclassResponse,
                    ] = await Promise.all([
                        GetTeachersList(),
                        GetSubsidiaryList(),
                        GetClassDayList(),
                        GetLevelsList(),
                        GetUnitList(),
                        GetTypeClassList(),
                    ]);

                    if (teacherResponse.auth === false ||
                        subsidiaryResponse.auth === false ||
                        classdayResponse.auth === false ||
                        levelResponse.auth === false ||
                        unitResponse.auth === false ||
                        typeclassResponse.auth === false) {
                        window.location.reload()
                        return;
                    }
                    // Actualizar todas las listas en un solo setDataLists
                    setDataLists(prevState => ({
                        ...prevState,
                        teacherList: teacherResponse.result.map(element => ({
                            value: element.employee_id,
                            label: element.name,
                            subsidiary: {
                                id: element.subsidiary_id,
                                name: element.subsidiary_name
                            }
                        })),
                        subsidiaryList: subsidiaryResponse.result.map(element => ({
                            value: element.id,
                            label: element.name,
                            classrooms: element.classrooms
                        })),
                        classdayList: classdayResponse.result.map(element => ({
                            value: element.id,
                            label: element.name,
                            initial_name: getInitial(element.name)
                        })),
                        levelList: {
                            adultList: levelResponse.result.adults.map(element => ({
                                value: element.id,
                                label: element.name,
                                units: element.units
                            })),
                            // Asegúrate de conservar childrenList para que no se pierda su valor
                            childrenList: levelResponse.result.kids.map(element => ({
                                value: element.id,
                                label: element.name,
                                units: element.units
                            })),
                        },
                        unitList: unitResponse.result.map(element => ({
                            value: element.id,
                            label: element.name
                        })),
                        typeclassList: typeclassResponse.result.map(element => ({
                            value: element.id,
                            label: element.name
                        })),
                    }));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            const userInfoPreparing = async () => {
                try {
                    const objectUser = props.usr;
                    // Evalúa el privilegio del usuario
                    const isPrivilegedAdmin = objectUser.idPriv === 5;
                    const isDirector = objectUser.privilege_id === 4;
                    setDisableSubsidiary(!(isPrivilegedAdmin || isDirector));
                } catch (error) {
                    console.error("Error configuring user info:", error);
                }
            };
            const prepareDataToEdit = async () => {
                let DataToEdit = props.editData;
                setGrupoTitle(`${DataToEdit.teacher.name} ${DataToEdit.full_dayclass}`)
                setIdGroup(DataToEdit.id)
                setTeacher(DataToEdit.teacher.id)
                setStGroup(DataToEdit.st)
                setCheckPart(DataToEdit.level.idUnitPart === null ? "" : DataToEdit.level.idUnitPart)
                setCheckDayOff((DataToEdit.day_off === 0 || DataToEdit.day_off === null) ? false : true)
                setCheckOnline(DataToEdit.is_online === 0 || DataToEdit.is_online === null ? false : true)
                // Configura la sucursal seleccionada
                setSubsidiarySelected(DataToEdit.subsidiary.id);
                if (DataToEdit.level.id !== null && DataToEdit.level.id !== "") {
                    if (DataToEdit.level.id > 5) {
                        setAgeGroup("children")
                        setLevelSelected(DataToEdit.level.id)
                        setUnitSelected(DataToEdit.level.idUnit)
                    } else {
                        setAgeGroup("adults")
                        setLevelSelected(DataToEdit.level.id)
                        setUnitSelected(DataToEdit.level.idUnit)
                    }
                } else {
                    setAgeGroup("")
                    setLevelSelected("")
                    setUnitSelected("")
                }
                if (DataToEdit.class_day) {
                    setSelectedDays(prevDays => [
                        ...prevDays,
                        ...DataToEdit.class_day.map(v => parseInt(v.id))
                    ]);
                }
                setTypeClassSelected((DataToEdit.type_class === null || DataToEdit.type_class === "") ? "" : DataToEdit.type_class)
                setDateStartSelected(DataToEdit.date.start !== null ? ConvertDateFormat(DataToEdit.date.start) : "")
                setDateFinishedSelected(props.editData.date.end !== null ? ConvertDateFormat(props.editData.date.end) : "")
                setTimeStart(DataToEdit.time.start)
                setTimeEnd(DataToEdit.time.end)
                setClassroomSelected(DataToEdit.classroom.id === null ? "" : DataToEdit.classroom.id)
                setActiveStundets(DataToEdit.students_qty)
                setStudentLimit(DataToEdit.student_limit || "")
                setAgeStart(DataToEdit.age_range.start || '')
                setAgeEnd(DataToEdit.age_range.end || '')
                setObservations(DataToEdit.observation || '')
                setCheckFinished(DataToEdit.is_finished === null || DataToEdit.is_finished === 0 ? false : true)

                //disables
                setDisableTimeStart(DataToEdit.time.start && false)


            }

            // Ejecutar ambas funciones asincrónicamente
            const executeAsyncTasks = async () => {
                await fetchData();
                await userInfoPreparing();
                await prepareDataToEdit();
            };

            executeAsyncTasks();
        }
    }, [props.open])

    useEffect(() => {
        if (props.open === true) {
            if (subsidiarySelected && dataLists.subsidiaryList.length > 0) {
                const selectedSubsidiary = dataLists.subsidiaryList.find(
                    (subsidiary) => subsidiary.value === parseInt(subsidiarySelected)
                );
                if (selectedSubsidiary) {
                    setDataLists((prevState) => ({
                        ...prevState,
                        classroomList: selectedSubsidiary.classrooms.map((classroom) => ({
                            value: classroom.id,
                            label: classroom.name
                        }))
                    }));
                    const filterTeachers = selectedSubsidiary ? dataLists.teacherList.filter(teacher => teacher.subsidiary.id === selectedSubsidiary.value) : []
                    setFilterTeachers(filterTeachers)
                }
            }
        }
    }, [subsidiarySelected, dataLists.subsidiaryList]);

    useEffect(() => {
        if (props.open === true) {
            if (levelSelected !== "") {
                let ageGroupSel
                if (ageGroup === "children") {
                    ageGroupSel = "childrenList"
                } else {
                    ageGroupSel = "adultList"
                }
                // Encontrar el nivel seleccionado
                const levelChange = levelSelected &&
                    dataLists.levelList[ageGroupSel]?.find(item => item.value === parseInt(levelSelected));
                // Filtrar las unidades disponibles según el nivel seleccionado
                const filteredUnits = levelChange
                    ? dataLists.unitList.filter(unit => unit.value <= levelChange.units)
                    : []; // Si no hay nivel seleccionado, la lista queda vacía
                setFilterUnits(filteredUnits)
            }
        }
    }, [levelSelected])
    /////////////////////////////////////////////
    const ConfirmAction = () => {
        return new Promise((resolve) => {
            setIsModalConfirmOpen(true); // Abre el modal

            // Pasas estas funciones al modal para controlar los botones
            const handleAccept = () => {
                setIsModalConfirmOpen(false); // Cierra el modal
                resolve(true); // Resuelve la promesa con "true" si acepta
            };

            const handleCancel = () => {
                setIsModalConfirmOpen(false); // Cierra el modal
                resolve(false); // Resuelve la promesa con "false" si cancela
            };

            // Almacenar funciones en el estado para pasar al modal
            setModalHandlers({ handleAccept, handleCancel });
        });
    };

    /////////////////////////////////////////////
    const GetTeachersList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getAllTeachers/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetSubsidiaryList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getSubsidiaryList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetClassDayList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getClassDayList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    const GetLevelsList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "LevelsList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data

    }
    const GetUnitList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getUnitList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })

        return res.data
    }
    const GetTypeClassList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getTypeList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })

        return res.data
    }

    ///////////////////////////////////////
    const handleChangeTimeStart = (e) => {
        let [hour, minute] = e.target.value.split(":");

        // Redondear los minutos a 00 o 30
        if (minute !== "00" && minute !== "30") {
            minute = minute < 30 ? "00" : "30";
        }

        // Calcular la hora de finalización (+1 hora)
        let endHour = parseInt(hour) + 1;
        if (endHour === 24) {
            setTimeEnd("00:00"); // Si es 23:00 o 23:30, termina a las 00:00
        } else {
            let formattedEndHour = endHour < 10 ? `0${endHour}` : endHour;
            setTimeEnd(`${formattedEndHour}:${minute}`); // Mantiene el mismo minuto
        }

        // Establecer la hora de inicio con minutos corregidos
        setTimeStart(`${hour}:${minute}`);
    };
    const handleChangeTimeEnd = (e) => {
        let [hour, minute] = e.target.value.split(":");

        // Asegurar que los minutos sean solo 00 o 30
        if (minute !== "00" && minute !== "30") {
            minute = minute < 30 ? "00" : "30"; // Redondeo automático
        }

        setTimeEnd(`${hour}:${minute}`);
    };
    const PatchEditTeacherClass = async (group_id, teacher_id, level_id, unit_id, subsidiary_id, selectedDays, timeStart, timeEnd, day_off, date_start, date_end, is_online, unit_part_id, classroom_id, student_limit, age_start, age_end, type_lesson_id, observation, isFinished) => {
        const res = await Axios.patch(process.env.REACT_APP_API_URL + "updateTeacherClass/" + group_id, {
            teacher_id,
            level_id,
            unit_id,
            subsidiary_id,
            selectedDays,
            timeStart,
            timeEnd,
            day_off,
            date_start,
            date_end,
            is_online,
            unit_part_id,
            classroom_id,
            student_limit,
            age_start,
            age_end,
            type_lesson_id,
            observation,
            isFinished
        }, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const handleEditTeacherClass = () => {
        checkEmpty();
        var thereIsAlert = 0
        if (arrayEmptys.length === 0) {

            const tStart = parseInt(timeStart)
            const tEnd = parseInt(timeEnd)
            const checkHours = checkTime(tStart, tEnd)
            checkHours.then((hr) => {
                ////validar fecha inicia y fecha fin para que el no sea menor que el inicio
                if (hr.status === 200) {
                    const editTeacherClass = PatchEditTeacherClass(
                        idGroup,
                        teacher,
                        levelSelected,
                        unitSelected,
                        subsidiarySelected,
                        selectedDays,
                        timeStart,
                        timeEnd,
                        checkDayOff,
                        dateStartSelected,
                        dateFinishedSelected,
                        checkOnline,
                        checkPart,
                        classroomSelected,
                        studentLimit,
                        ageStart,
                        ageEnd,
                        typeClassSelected,
                        observations,
                        checkFinished,
                    )
                    editTeacherClass.then((ec) => {
                        if (ec.auth === false) {
                            setIsVisible(true)
                            setDisableAll(true)
                            setDisableTimeStart(true)
                            if (arrayAlerts.length <= 4) {
                                const objAlert = {
                                    id: arrayAlerts.length + 1,
                                    type: "danger",
                                    message: ec.message
                                }
                                if (arrayAlerts.length > 0) {
                                    arrayAlerts.forEach((v) => {
                                        if (v.message === objAlert.message) {
                                            thereIsAlert++
                                        }
                                    })
                                    if (thereIsAlert === 0) {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                } else {
                                    setArrayAlerts([...arrayAlerts, objAlert])
                                }
                            }
                            setTimeout(() => {
                                window.location.reload()

                            }, 1500)
                        } else {
                            switch (ec.status) {
                                case 200:
                                    setIsVisible(true)
                                    setDisableAll(true)
                                    setDisableTimeStart(true)
                                    props.setTeacherAddedFlag(Math.random())
                                    if (arrayAlerts.length <= 4) {
                                        const objAlert = {
                                            id: arrayAlerts.length + 1,
                                            type: "success",
                                            message: ec.message
                                        }
                                        if (arrayAlerts.length > 0) {
                                            arrayAlerts.forEach((v) => {
                                                if (v.message === objAlert.message) {
                                                    thereIsAlert++
                                                }
                                            })
                                            if (thereIsAlert === 0) {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        } else {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    }
                                    setTimeout(() => {
                                        props.onCloseButton()
                                        clearVariables()
                                    }, 2500);
                                    break;
                                case 201:
                                case 202:
                                case 203:
                                case 204:
                                case 205:
                                case 206:
                                case 500:
                                    setIsVisible(true)
                                    if (ec.status === 204) {
                                        setDateStartRequire("required")
                                        setDateFinishedRequire("required")
                                    } else if (ec.status === 205) {
                                        setAgeStartRequire("required")
                                        setAgeEndRequire("required")
                                    } else if (ec.status === 202 || ec.status === 203) {
                                        setTimeStartRequire("required")
                                        setTimeEndRequire("required")
                                        if (ec.status === 203) {
                                            setClassroomRequire("required")

                                        }
                                    }
                                    if (arrayAlerts.length <= 4) {
                                        const objAlert = {
                                            id: arrayAlerts.length + 1,
                                            type: "danger",
                                            message: ec.message
                                        }
                                        if (arrayAlerts.length > 0) {
                                            arrayAlerts.forEach((v) => {
                                                if (v.message === objAlert.message) {
                                                    thereIsAlert++
                                                }
                                            })
                                            if (thereIsAlert === 0) {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        } else {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    }
                                    break;

                                default:
                                    break;
                            }

                        }
                    })
                } else {

                    setIsVisible(true)
                    setTimeStartRequire("required")
                    if (arrayAlerts.length <= 4) {
                        const objAlert = {
                            id: arrayAlerts.length + 1,
                            type: "danger",
                            message: hr.message
                        }
                        if (arrayAlerts.length > 0) {
                            arrayAlerts.forEach((v) => {
                                if (v.message === objAlert.message) {
                                    thereIsAlert++
                                }
                            })
                            if (thereIsAlert === 0) {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        } else {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    }
                }
            })



        }
    }

    const handleDeleteTeacherClass = async () => {
        var thereIsAlert = 0
        setModalTitleAction("Eliminar Grupo")
        setModalDetailAction(`desea eliminar el grupo de ${grupoTitle}`)
        const confirm = await ConfirmAction()
        if (confirm) {
            const deleteEvent = DeleteTeacherClass(idGroup)
            deleteEvent.then((res) => {
                console.log(res)
                if (res.auth === false) {
                    setIsVisible(true)
                    setDisableAll(true)
                    setDisableTimeStart(true)
                    if (arrayAlerts.length <= 4) {
                        const objAlert = {
                            id: arrayAlerts.length + 1,
                            type: "danger",
                            message: res.message
                        }
                        if (arrayAlerts.length > 0) {
                            arrayAlerts.forEach((v) => {
                                if (v.message === objAlert.message) {
                                    thereIsAlert++
                                }
                            })
                            if (thereIsAlert === 0) {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        } else {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    }
                    setTimeout(() => {
                        window.location.reload()

                    }, 1500)
                } else {

                    const status = res.status
                    if (status === 200) {
                        ///se utiliza para actualziar la tabla
                        setIsVisible(true)
                        setDisableAll(true)
                        setDisableTimeStart(true)
                        props.setTeacherAddedFlag(Math.random())
                        if (arrayAlerts.length <= 4) {
                            const objAlert = {
                                id: arrayAlerts.length + 1,
                                type: "success",
                                message: res.message
                            }
                            if (arrayAlerts.length > 0) {
                                arrayAlerts.forEach((v) => {
                                    if (v.message === objAlert.message) {
                                        thereIsAlert++
                                    }
                                })
                                if (thereIsAlert === 0) {
                                    setArrayAlerts([...arrayAlerts, objAlert])
                                }
                            } else {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        }
                        setTimeout(() => {
                            props.onCloseButton()
                            clearVariables()
                        }, 2500);
                    }
                }
            })

        }
    }

    const DeleteTeacherClass = async (idClass) => {
        const res = await Axios.delete(process.env.REACT_APP_API_URL + "deleteTeacherClass/" + idClass, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    // const handleConfirmAction = () => {
    //     const optionAction = optionState
    //     switch (optionAction) {
    //         case 1:
    //             confirmDeleteTeacherClass()
    //             break;

    //         default:
    //             break;
    //     }

    // }
    // const handleCancelAction = () => {
    //     setOpenConfirm(false)
    // }
    // const handleDeleteTeacherClass = () => {
    //     setOpenConfirm(true)
    //     setOptionState(1)
    // }

    const checkTime = async (timeStart, timeEnd) => {
        var res
        if (timeEnd <= timeStart) {
            res = await {
                status: 201,
                message: "Verifica que la hora final sea menor a la hora inicial"
            }

        } else {
            res = await {
                status: 200,
                message: "Se puede asignar clase a este horario"
            }
        }
        return res
    }
    const checkEmpty = () => {
        arrayEmptys.length = 0
        if (teacher === "") {
            arrayEmptys.push("teacher")
            setTeacherRequire("required")
        } else {
            setTeacherRequire("")

        }
        if (levelSelected === "" && checkDayOff === false) {
            arrayEmptys.push("book")
            setLevelRequire("required")
        } else {
            setLevelRequire("")

        }
        if (unitSelected === "" && checkDayOff === false) {
            arrayEmptys.push("unit")
            setUnitRequire("required")
        } else {
            setUnitRequire("")

        }
        if (subsidiarySelected === "") {
            arrayEmptys.push("subsidiarySelected")
            setSubsidiaryRequire("required")
        } else {
            setSubsidiaryRequire("")
        }

        if (selectedDays.length === 0) {
            arrayEmptys.push("classday")
            setClassDayRequire("required")
        } else {
            setClassDayRequire("")

        }
        if (dateStartSelected === "" && checkDayOff === false) {
            arrayEmptys.push("dateStartSelected")
            setDateStartRequire("required")
        } else {
            setDateStartRequire("")

        }
        if (timeStart === "" || timeStart === ":30") {
            arrayEmptys.push("timeStart")
            setTimeStartRequire("required")
        } else {
            setTimeStartRequire("")

        }
        if (timeEnd === "" || timeEnd === ":30") {
            arrayEmptys.push("timeEnd")
            setTimeEndRequire("required")
        } else {
            setTimeEndRequire("")
        }
        if (typeClassSelected === "" && checkDayOff === false) {
            arrayEmptys.push("typeClass")
            setTypeClassRequire("required")
        } else {
            setTypeClassRequire("")
        }

        if (classroomSelected === "" && checkDayOff === false) {
            arrayEmptys.push("classroom")
            setClassroomRequire("required")
        } else {
            setClassroomRequire("")
        }
        if (ageGroup === "" && checkDayOff === false) {
            arrayEmptys.push("ageGroup")
            setAgeGroupRequire("required")
        } else {
            setAgeGroupRequire("")
        }
        if (checkFinished === true && checkDayOff === false) {

            if (dateFinishedSelected === "") {
                arrayEmptys.push("dateFinished")
                setDateFinishedRequire("required")
            } else {
                setDateFinishedRequire("")

            }
        }
    }

    const clearVariables = () => {
        setDisableAll(false)
        setDisableTimeStart(true)
        setTeacher("")
        setSelectedDays([])
        setSubsidiarySelected("")
        setUnitSelected("")
        setLevelSelected("")
        setTimeStart("")
        setTimeEnd("")
        setIsVisible(false)
        setArrayAlerts([])
        setDateStartSelected("")
        setCheckDayOff(false)
        setCheckOnline(false)
        setTypeClassSelected("")
        setClassroomSelected("")
        setAgeStart("")
        setAgeEnd("")
        setObservations("")
        setAgeGroup("")
        setCheckFinished(false)
        ///require
        setTeacherRequire("")
        setTypeClassRequire("")
        setAgeGroupRequire("")
        setLevelRequire("")
        setUnitRequire("")
        setTimeStartRequire("")
        setTimeEndRequire("")
        setSubsidiaryRequire("")
        setClassDayRequire("")
        setDateStartRequire("")
        setAgeStartRequire("")
        setAgeEndRequire("")
        setClassroomRequire("")
    }

    const handleCheckDayOffOnline = (checkboxN) => {
        switch (checkboxN) {
            case 0:
                setCheckDayOff(!checkDayOff)

                break;
            case 1:
                setCheckOnline(!checkOnline)

                break;
            case 2:
                setCheckFinished(!checkFinished)
                break;
            default:
                break;
        }
    }


    const handleCheckedPart = (part) => {
        switch (part) {
            case 1:
                if (checkPart !== 1) {
                    setCheckPart(1)

                } else {
                    setCheckPart("")
                }
                break;
            case 2:
                if (checkPart !== 2) {
                    setCheckPart(2)
                } else {
                    setCheckPart("")

                }

                break;
            default:
                break;
        }
    }

    const ConvertDateFormat = (date) => {
        var convert = new Date(date),
            mnth = ("0" + (convert.getMonth() + 1)).slice(-2),
            day = ("0" + convert.getDate()).slice(-2);
        return [convert.getFullYear(), mnth, day].join("-")
    }
    ////////////////////////////////////////////////////////////////////////////////////////////
    const ageGroupOptions = [
        { value: 'children', label: 'Niños' },
        { value: 'adults', label: 'Adultos' },
    ];


    // LISTAS DE PRUEBA DE SHARON !!!!!!!!ESIMPORTANTE QUITARLOS!!!!!!!

    //mañana realizar api para lista de salones por sucursal y ponerlos despues realizar el agregado de classes
    const handleCheckboxChange = (event, dayId) => {
        if (event.target.checked) {
            setSelectedDays([...selectedDays, dayId]); // Agrega el día seleccionado
        } else {
            setSelectedDays(selectedDays.filter(id => id !== dayId)); // Remueve el día si se desmarca
        }
    };
    // 👇 Este efecto se ejecuta cada vez que cambia `selectedDays`
    useEffect(() => {
        setDisableTimeStart(selectedDays.length === 0); // Si no hay días seleccionados, deshabilita el input
        setDisableTimeEnd(selectedDays.length === 0); // Si no hay días seleccionados, deshabilita el input
        setTimeStart(selectedDays.length === 0 ? '' : timeStart)
        setTimeEnd(selectedDays.length === 0 ? '' : timeEnd)

    }, [selectedDays]);
    return (
        <>
            <Modal
                isOpen={props.open}
                overlayClick={false}
            >
                <div className='edit-teacher-class'>
                    <section className="header">
                        <h2 className='title-1'>Editar grupo</h2>
                        <h2 className='title-3'>{grupoTitle}</h2>
                        <div className='checks-inp'>
                            <InputAnimated type='checkbox' placeholder='Hora disponible' checked={checkDayOff} onChange={() => { handleCheckDayOffOnline(0) }}></InputAnimated>
                            {
                                !checkDayOff &&
                                <>
                                    <InputAnimated type='checkbox' placeholder='Clase online' checked={checkOnline} onChange={() => { handleCheckDayOffOnline(1) }}></InputAnimated>
                                    <InputAnimated type='checkbox' placeholder='Finalizó' checked={checkFinished} onChange={() => { handleCheckDayOffOnline(2) }}></InputAnimated>
                                </>
                            }
                        </div>
                    </section>
                    <section className="content">
                        <div className='section'>
                            <Select
                                disabled={disableAll}
                                options={filterTeachers}
                                placeholder="Maestro"
                                className={'main-color ' + teacherRequire}
                                onChange={(e) => setTeacher(e.target.value)}
                                value={teacher}
                            />
                            <Select
                                disabled={disableSubsidiary || disableAll}
                                options={dataLists.subsidiaryList}
                                placeholder="Sucursal"
                                className={'main-color ' + subsidiaryRequire}
                                onChange={(e) => setSubsidiarySelected(e.target.value)}
                                value={subsidiarySelected}
                            />
                            {!checkDayOff &&
                                <>
                                    <Select
                                        options={dataLists.typeclassList}
                                        placeholder="Tipo de clase"
                                        className={'main-color ' + typeClassRequire}
                                        onChange={(e) => setTypeClassSelected(e.target.value)}
                                        value={typeClassSelected}
                                    />
                                    <Select
                                        options={ageGroupOptions}
                                        placeholder="Niños / Adultos"
                                        className={'main-color ' + agegroupRequire}
                                        onChange={(e) => { setAgeGroup(e.target.value); setLevelSelected(""); setUnitSelected("") }} // Actualizar grupo de edad
                                        value={ageGroup}
                                    />
                                    <Select
                                        options={ageGroup === 'adults' ? dataLists.levelList.adultList : dataLists.levelList.childrenList} // Opciones dinámicas basadas en el grupo de edad
                                        placeholder="Nivel"
                                        className={'main-color ' + levelRequire}
                                        onChange={(e) => { setLevelSelected(e.target.value); setUnitSelected("") }}
                                        value={levelSelected}
                                        disabled={!ageGroup ? true : false}
                                    />
                                    <Select
                                        options={filterUnits}
                                        placeholder="Unidad"
                                        className={'main-color ' + unitRequire}
                                        onChange={(e) => setUnitSelected(e.target.value)}
                                        value={unitSelected}
                                        disabled={!levelSelected ? true : false}
                                    />
                                    <div className='unit-parts'>
                                        <InputAnimated type='checkbox' placeholder='A' checked={checkPart === 1} onChange={() => handleCheckedPart(1)}></InputAnimated>
                                        <InputAnimated type='checkbox' placeholder='B' checked={checkPart === 2} onChange={() => handleCheckedPart(2)}></InputAnimated>
                                    </div>
                                </>
                            }
                            <div className='date-time'>
                                <h1 className='title-2'>Horario</h1>
                                <div className={'class-days ' + classDayRequire}>
                                    {dataLists.classdayList.map((day) => (
                                        <InputAnimated
                                            key={day.value}
                                            className={'main-color checkbox_days '}
                                            type='checkbox'
                                            value={day.value} // Asigna el ID del día
                                            checked={selectedDays.includes(day.value)} // Verifica si está seleccionado
                                            onChange={(e) => handleCheckboxChange(e, day.value)}
                                            placeholder={day.initial_name} // Muestra la letra del día
                                        />
                                    ))}
                                </div>
                                <div className='time-inputs'>
                                    {!checkDayOff &&
                                        <>
                                            <InputAnimated className={dateStartRequire} type="date" placeholder="Fecha de inicio" value={dateStartSelected} onChange={(e) => setDateStartSelected(e.target.value)} />
                                            {
                                                checkFinished === true &&
                                                <InputAnimated className={dateFinishedRequire} type="date" placeholder="Fecha de finalizacion" value={dateFinishedSelected} onChange={(e) => setDateFinishedSelected(e.target.value)} />
                                            }
                                        </>

                                    }
                                    <InputAnimated type="time" placeholder="Inicia" className={timeStartRequire} value={timeStart} disabled={disableTimeStart} onChange={(e) => { handleChangeTimeStart(e) }} step="3600" />
                                    <InputAnimated type="time" placeholder="Termina" className={timeEndRequire} value={timeEnd} disabled={disableTimeEnd} onChange={(e) => { handleChangeTimeEnd(e) }} step="3600" />
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            {!checkDayOff &&
                                <>
                                    <Select
                                        options={dataLists.classroomList} // Opciones dinámicas basadas en CLASSROOMS
                                        placeholder="Salón"
                                        className={'main-color ' + classroomRequire}
                                        onChange={(e) => setClassroomSelected(e.target.value)}
                                        value={classroomSelected}
                                    />
                                    <div className='students-qty'>
                                        <InputAnimated type="text" placeholder="Estudiantes activos" disabled value={activeStudents} />
                                        <InputAnimated type="text" placeholder="Limitar cupo" onChange={(e) => setStudentLimit(e.target.value)} value={studentLimit} />
                                    </div>

                                    <div className='age-range'>
                                        <h1 className='title-2'>Rango de edad</h1>
                                        <div className='age-inputs'>
                                            <InputAnimated className={ageStartRequire} type="number" placeholder="Edad mínima" value={ageStart} onChange={(e) => setAgeStart(e.target.value)} />
                                            <InputAnimated className={ageEndRequire} type="number" placeholder="Edad máxima" value={ageEnd} onChange={(e) => setAgeEnd(e.target.value)} />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className='notes'>
                                <h1 className='title-2'>Observaciones</h1>
                                <textarea className='main-color' placeholder="Escribe aquí las observaciones..." onChange={(e) => setObservations(e.target.value)} value={observations}></textarea>
                            </div>
                        </div>
                    </section>
                    <section className="footer">
                        <div className='delete-section'>
                            <Button disabled={(stGroup === 0 ||stGroup === '0') && checkFinished === false ? false : true} text='' backgroundColor='red' Icon={TrashSVG} onClick={handleDeleteTeacherClass}></Button>
                        </div>
                        <div className='confirm-buttons-section'>
                            <Button  text='Cancelar' onClick={() => { props.onCloseButton(); clearVariables() }} backgroundColor='red'></Button>
                            <Button disabled={(stGroup === 0 ||stGroup === '0')  ? false : true} text='Guardar' onClick={() => { handleEditTeacherClass() }}></Button>
                        </div>

                    </section>
                </div>
            </Modal>
            <div className="divAlert">
                <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisible} setIsVisibleModule={setIsVisible} />

            </div>
            <ConfirmModal
                isOpen={isModalConfirmOpen}
                handleAccept={modalHandlers.handleAccept}
                handleCancel={modalHandlers.handleCancel}
                text={modalDetailAction}
                title={modalTitleAction}
            />
        </>
    )
}
export default ModalEditTeacherClass