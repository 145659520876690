import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./modalAddTeacherClass.scss"
import InputAnimated from "../../../inputAnimated/inputAnimated";
import Alert from "../../../alert/Alert";
import Modal from "../../../modal/modal";
import Select from "../../../select/select";
import Button from "../../../button/button"
const ModalAddTeacherClass = (props) => {

    const [checkDayOff, setCheckDayOff] = useState(false)
    const [checkOnline, setCheckOnline] = useState(false)
    const [teacher, setTeacher] = useState("")
    const [typeClassSelected, setTypeClassSelected] = useState("")
    const [subsidiarySelected, setSubsidiarySelected] = useState("")
    const [unitSelected, setUnitSelected] = useState("")
    const [levelSelected, setLevelSelected] = useState("")
    const [classroomSelected, setClassroomSelected] = useState("")
    const [dateStartSelected, setDateStartSelected] = useState("")
    const [selectedDays, setSelectedDays] = useState([]);
    const [timeStart, setTimeStart] = useState("")
    const [ageStart, setAgeStart] = useState("")
    const [ageEnd, setAgeEnd] = useState("")
    const [timeEnd, setTimeEnd] = useState("")
    const [observations, setObservations] = useState("")
    const [ageGroup, setAgeGroup] = useState('');
    const [arrayAlerts, setArrayAlerts] = useState([])

    var arrayEmptys = []
    //LIST 
    const [dataLists, setDataLists] = useState({
        teacherList: [],
        subsidiaryList: [],
        classdayList: [],
        levelList: {
            adultList: [],
            childrenList: []
        },
        unitList: [],
        typeclassList: [],
        classroomList: []
    });
    const [filterUnits, setFilterUnits] = useState([])
    const [filterTeachers, setFilterTeachers] = useState([])
    //
    const ageGroupOptions = [
        { value: 'children', label: 'Niños' },
        { value: 'adults', label: 'Adultos' },
    ];
    /////requires
    const [teacherRequire, setTeacherRequire] = useState("")
    const [levelRequire, setLevelRequire] = useState("")
    const [unitRequire, setUnitRequire] = useState("")
    const [dateStartRequire, setDateStartRequire] = useState("")
    const [timeStartRequire, setTimeStartRequire] = useState("")
    const [timeEndRequire, setTimeEndRequire] = useState("")
    const [subsidiaryRequire, setSubsidiaryRequire] = useState("")
    const [classDayRequire, setClassDayRequire] = useState("")
    const [typeClassRequire, setTypeClassRequire] = useState("")
    const [classroomRequire, setClassroomRequire] = useState("")
    const [ageStartRequire, setAgeStartRequire] = useState("")
    const [ageEndRequire, setAgeEndRequire] = useState("")
    const [agegroupRequire, setAgeGroupRequire] = useState("")
    ///////disabled time inputs
    const [disableTimeStart, setDisableTimeStart] = useState(true);
    const [disableTimeEnd, setDisableTimeEnd] = useState(true);

    const [disableAll, setDisableAll] = useState(false);
    const [disableSubsidiary, setDisableSubsidiary] = useState(false)
    //////////////
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (props.open === true) {
            const getInitial = (str) => str.charAt(0).toUpperCase();
            const fetchData = async () => {

                try {
                    const objectUser = props.usr;
                    const isSuperAdmin = objectUser.idPriv === 5;  // Suponiendo que 5 es el rol de superadmin

                    // Si no es superadmin, se filtra por sucursal
                    const subsidiaryId = isSuperAdmin ? null : objectUser.subsidiary_id;
                    // Ejecutar todas las promesas en paralelo
                    const [
                        teacherResponse,
                        subsidiaryResponse,
                        classdayResponse,
                        levelResponse,
                        unitResponse,
                        typeclassResponse,
                    ] = await Promise.all([
                        GetTeachersList(subsidiaryId),
                        GetSubsidiaryList(),
                        GetClassDayList(),
                        GetLevelsList(),
                        GetUnitList(),
                        GetTypeClassList(),
                    ]);

                    if (teacherResponse.auth === false ||
                        subsidiaryResponse.auth === false ||
                        classdayResponse.auth === false ||
                        levelResponse.auth === false ||
                        unitResponse.auth === false ||
                        typeclassResponse.auth === false) {
                        window.location.reload()
                        return;
                    }
                    // Actualizar todas las listas en un solo setDataLists
                    setDataLists(prevState => ({
                        ...prevState,
                        teacherList: teacherResponse.result.map(element => ({
                            value: element.employee_id,
                            label: element.name,
                            subsidiary: {
                                id: element.subsidiary_id,
                                name: element.subsidiary_name
                            }
                        })),
                        subsidiaryList: subsidiaryResponse.result.map(element => ({
                            value: element.id,
                            label: element.name,
                            classrooms: element.classrooms
                        })),
                        classdayList: classdayResponse.result.map(element => ({
                            value: element.id,
                            label: element.name,
                            initial_name: getInitial(element.name)
                        })),
                        levelList: {
                            adultList: levelResponse.result.adults.map(element => ({
                                value: element.id,
                                label: element.name,
                                units: element.units
                            })),
                            // Asegúrate de conservar childrenList para que no se pierda su valor
                            childrenList: levelResponse.result.kids.map(element => ({
                                value: element.id,
                                label: element.name,
                                units: element.units
                            })),
                        },
                        unitList: unitResponse.result.map(element => ({
                            value: element.id,
                            label: element.name
                        })),
                        typeclassList: typeclassResponse.result.map(element => ({
                            value: element.id,
                            label: element.name
                        })),
                    }));

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            const userInfoPreparing = async () => {
                try {
                    const objectUser = props.usr;
                    // Configura la sucursal seleccionada
                    setSubsidiarySelected(objectUser.subsidiary_id);
                    // Evalúa el privilegio del usuario
                    const isPrivilegedAdmin = objectUser.idPriv === 5;
                    const isDirector = objectUser.privilege_id === 4;
                    setDisableSubsidiary(!(isPrivilegedAdmin || isDirector));
                } catch (error) {
                    console.error("Error configuring user info:", error);
                }
            };

            // Ejecutar ambas funciones asincrónicamente
            const executeAsyncTasks = async () => {
                await fetchData();
                await userInfoPreparing();
            };

            executeAsyncTasks();
        }
    }, [props.open])

    const GetTeachersList = async (subsidiaryId = null) => {
        let url
        if (subsidiaryId) {
            url = `getAllTeachersBySubsidiary/${subsidiaryId}`
        } else {
            url = 'getAllTeachers/'

        }
        const res = await Axios.get(process.env.REACT_APP_API_URL + url, {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetSubsidiaryList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getSubsidiaryList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }
    const GetClassDayList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getClassDayList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data
    }

    const GetLevelsList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "LevelsList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })
        return res.data

    }
    const GetUnitList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getUnitList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })

        return res.data
    }
    const GetTypeClassList = async () => {
        const res = await Axios.get(process.env.REACT_APP_API_URL + "getTypeList/", {
            headers: {
                "x-access-token": localStorage.getItem("token"),
            },
        })

        return res.data
    }
    /////////////////////////////////////
    // Nuevo useEffect para actualizar classroomList cuando subsidiarySelected cambia
    useEffect(() => {
        if (props.open === true) {
            if (subsidiarySelected && dataLists.subsidiaryList.length > 0) {
                const selectedSubsidiary = dataLists.subsidiaryList.find(
                    (subsidiary) => subsidiary.value === parseInt(subsidiarySelected)
                );
                if (selectedSubsidiary) {
                    setDataLists((prevState) => ({
                        ...prevState,
                        classroomList: selectedSubsidiary.classrooms.map((classroom) => ({
                            value: classroom.id,
                            label: classroom.name
                        }))
                    }));
                    const filterTeachers = selectedSubsidiary ? dataLists.teacherList.filter(teacher => teacher.subsidiary.id === selectedSubsidiary.value) : []
                    setFilterTeachers(filterTeachers)
                    setTeacher('')
                    setClassroomSelected('')
                }
            }
        }
    }, [subsidiarySelected, dataLists.subsidiaryList]);

    useEffect(() => {
        if (props.open === true) {
            if (levelSelected !== "") {
                let ageGroupSel
                if (ageGroup === "children") {
                    ageGroupSel = "childrenList"
                } else {
                    ageGroupSel = "adultList"
                }
                // Encontrar el nivel seleccionado
                const levelChange = levelSelected &&
                    dataLists.levelList[ageGroupSel]?.find(item => item.value === parseInt(levelSelected));
                // Filtrar las unidades disponibles según el nivel seleccionado
                const filteredUnits = levelChange
                    ? dataLists.unitList.filter(unit => unit.value <= levelChange.units)
                    : []; // Si no hay nivel seleccionado, la lista queda vacía
                setFilterUnits(filteredUnits)
            }
        }
    }, [levelSelected])
    /////////////////////////////////////

    const handleChangeTimeStart = (e) => {
        let [hour, minute] = e.target.value.split(":");

        // Redondear los minutos a 00 o 30
        if (minute !== "00" && minute !== "30") {
            minute = minute < 30 ? "00" : "30";
        }

        // Calcular la hora de finalización (+1 hora)
        let endHour = parseInt(hour) + 1;
        if (endHour === 24) {
            setTimeEnd("00:00"); // Si es 23:00 o 23:30, termina a las 00:00
        } else {
            let formattedEndHour = endHour < 10 ? `0${endHour}` : endHour;
            setTimeEnd(`${formattedEndHour}:${minute}`); // Mantiene el mismo minuto
        }

        // Establecer la hora de inicio con minutos corregidos
        setTimeStart(`${hour}:${minute}`);
    };
    const handleChangeTimeEnd = (e) => {
        let [hour, minute] = e.target.value.split(":");

        // Asegurar que los minutos sean solo 00 o 30
        if (minute !== "00" && minute !== "30") {
            minute = minute < 30 ? "00" : "30"; // Redondeo automático
        }

        setTimeEnd(`${hour}:${minute}`);
    };

    const confirmAddTeacher = () => {
        checkEmpty();
        var thereIsAlert = 0
        if (arrayEmptys.length === 0) {

            const tStart = parseInt(timeStart)
            const tEnd = parseInt(timeEnd)
            const checkHours = checkTime(tStart, tEnd)
            checkHours.then((hr) => {
                if (hr.status === 200) {
                    const createTeacherClass = PostCreateTeacherClass(teacher, levelSelected, unitSelected, subsidiarySelected, selectedDays, timeStart, timeEnd, checkDayOff, dateStartSelected, checkOnline, classroomSelected, ageStart, ageEnd, typeClassSelected, observations)
                    createTeacherClass.then((tc) => {
                        if (tc.auth === false) {
                            setIsVisible(true)
                            setDisableAll(true)
                            setDisableTimeStart(true)
                            if (arrayAlerts.length <= 4) {
                                const objAlert = {
                                    id: arrayAlerts.length + 1,
                                    type: "danger",
                                    message: tc.message
                                }
                                if (arrayAlerts.length > 0) {
                                    arrayAlerts.forEach((v) => {
                                        if (v.message === objAlert.message) {
                                            thereIsAlert++
                                        }
                                    })
                                    if (thereIsAlert === 0) {
                                        setArrayAlerts([...arrayAlerts, objAlert])
                                    }
                                } else {
                                    setArrayAlerts([...arrayAlerts, objAlert])
                                }
                            }
                            setTimeout(() => {
                                window.location.reload()

                            }, 1500)
                        } else {

                            switch (tc.status) {
                                case 200:
                                    setIsVisible(true)
                                    setDisableAll(true)
                                    setDisableTimeStart(true)
                                    props.setTeacherAddedFlag(Math.random())
                                    if (arrayAlerts.length <= 4) {
                                        const objAlert = {
                                            id: arrayAlerts.length + 1,
                                            type: "success",
                                            message: tc.message
                                        }
                                        if (arrayAlerts.length > 0) {
                                            arrayAlerts.forEach((v) => {
                                                if (v.message === objAlert.message) {
                                                    thereIsAlert++
                                                }
                                            })
                                            if (thereIsAlert === 0) {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        } else {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    }
                                    if (localStorage.getItem("teacherAdded")) {
                                        let numberTeacherAdded = JSON.parse(localStorage.getItem("teacherAdded"))
                                        numberTeacherAdded = numberTeacherAdded + 1
                                        localStorage.setItem("teacherAdded", JSON.stringify(numberTeacherAdded))
                                    } else {
                                        localStorage.setItem("teacherAdded", JSON.stringify(1))
                                    }
                                    setTimeout(() => {
                                        props.onCloseButton()
                                        clearVariables()
                                    }, 2500);
                                    break;
                                case 201:
                                case 202:
                                case 203:
                                case 204:
                                case 205:
                                    setIsVisible(true)
                                    if (tc.status === 203 || tc.status === 204) {
                                        setTimeStartRequire("required")
                                        setTimeEndRequire("required")
                                        if(tc.status === 203){
                                           setClassroomRequire("required")
                                        }
                                    } else if (tc.status === 202) {
                                        setAgeStartRequire("required")
                                        setAgeEndRequire("required")
                                    }
                                    if (arrayAlerts.length <= 4) {
                                        const objAlert = {
                                            id: arrayAlerts.length + 1,
                                            type: "danger",
                                            message: tc.message
                                        }
                                        if (arrayAlerts.length > 0) {
                                            arrayAlerts.forEach((v) => {
                                                if (v.message === objAlert.message) {
                                                    thereIsAlert++
                                                }
                                            })
                                            if (thereIsAlert === 0) {
                                                setArrayAlerts([...arrayAlerts, objAlert])
                                            }
                                        } else {
                                            setArrayAlerts([...arrayAlerts, objAlert])
                                        }
                                    }
                                    break;


                                default:
                                    break;
                            }
                        }
                    })
                } else {
                    setIsVisible(true)
                    setTimeStartRequire("required")
                    setTimeEndRequire("required")
                    if (arrayAlerts.length <= 4) {
                        const objAlert = {
                            id: arrayAlerts.length + 1,
                            type: "danger",
                            message: hr.message
                        }
                        if (arrayAlerts.length > 0) {
                            arrayAlerts.forEach((v) => {
                                if (v.message === objAlert.message) {
                                    thereIsAlert++
                                }
                            })
                            if (thereIsAlert === 0) {
                                setArrayAlerts([...arrayAlerts, objAlert])
                            }
                        } else {
                            setArrayAlerts([...arrayAlerts, objAlert])
                        }
                    }
                }
            })


        }
    }

    const checkTime = async (timeStart, timeEnd) => {
        var res
        if (timeEnd <= timeStart) {
            res = await {
                status: 201,
                message: "Verifica que la hora final sea menor a la hora inicial"
            }

        } else {
            res = await {
                status: 200,
                message: "Se puede asignar clase a este horario"
            }
        }
        return res
    }

    const PostCreateTeacherClass = async (teacher_id, level_id, unit_id, subsidiary_id, selectedDays, timeStart, timeEnd, day_off, date_start, is_online, classroom_id, age_start, age_end, type_lesson_id, observation) => {
        const res = await Axios.post(process.env.REACT_APP_API_URL + "postCreateTeacherClass/", {
            teacher_id,
            level_id,
            unit_id,
            subsidiary_id,
            selectedDays,
            timeStart,
            timeEnd,
            day_off,
            date_start,
            is_online,
            classroom_id,
            age_start,
            age_end,
            type_lesson_id,
            observation
        },
            {
                headers: {
                    "x-access-token": localStorage.getItem("token"),
                },
            })
        return res.data
    }

    const checkEmpty = () => {
        arrayEmptys.length = 0
        if (teacher === "") {
            arrayEmptys.push("teacher")
            setTeacherRequire("required")
        } else {
            setTeacherRequire("")

        }
        if (levelSelected === "" && checkDayOff === false) {
            arrayEmptys.push("book")
            setLevelRequire("required")
        } else {
            setLevelRequire("")

        }
        if (unitSelected === "" && checkDayOff === false) {
            arrayEmptys.push("unit")
            setUnitRequire("required")
        } else {
            setUnitRequire("")

        }
        if (subsidiarySelected === "") {
            arrayEmptys.push("subsidiarySelected")
            setSubsidiaryRequire("required")
        } else {
            setSubsidiaryRequire("")
        }

        if (selectedDays.length === 0) {
            arrayEmptys.push("classday")
            setClassDayRequire("required")
        } else {
            setClassDayRequire("")

        }
        if (dateStartSelected === "" && checkDayOff === false) {
            arrayEmptys.push("dateStartSelected")
            setDateStartRequire("required")
        } else {
            setDateStartRequire("")

        }
        if (timeStart === "" || timeStart === ":30") {
            arrayEmptys.push("timeStart")
            setTimeStartRequire("required")
        } else {
            setTimeStartRequire("")

        }
        if (timeEnd === "" || timeEnd === ":30") {
            arrayEmptys.push("timeEnd")
            setTimeEndRequire("required")
        } else {
            setTimeEndRequire("")
        }
        if (typeClassSelected === "" && checkDayOff === false) {
            arrayEmptys.push("typeClass")
            setTypeClassRequire("required")
        } else {
            setTypeClassRequire("")
        }

        if (classroomSelected === "" && checkDayOff === false) {
            arrayEmptys.push("classroom")
            setClassroomRequire("required")
        } else {
            setClassroomRequire("")
        }
        if (ageGroup === "" && checkDayOff === false) {
            arrayEmptys.push("ageGroup")
            setAgeGroupRequire("required")
        } else {
            setAgeGroupRequire("")
        }
    }

    const clearVariables = () => {
        setDisableAll(false)
        setDisableTimeStart(true)
        setTeacher("")
        setSelectedDays([])
        setSubsidiarySelected("")
        setUnitSelected("")
        setLevelSelected("")
        setTimeStart("")
        setTimeEnd("")
        setIsVisible(false)
        setArrayAlerts([])
        setDateStartSelected("")
        setCheckDayOff(false)
        setCheckOnline(false)
        setTypeClassSelected("")
        setClassroomSelected("")
        setAgeStart("")
        setAgeEnd("")
        setObservations("")
        setAgeGroup("")
        ///require
        setTeacherRequire("")
        setTypeClassRequire("")
        setAgeGroupRequire("")
        setLevelRequire("")
        setUnitRequire("")
        setTimeStartRequire("")
        setTimeEndRequire("")
        setSubsidiaryRequire("")
        setClassDayRequire("")
        setDateStartRequire("")
        setAgeStartRequire("")
        setAgeEndRequire("")
        setClassroomRequire("")
    }
    ///////////////Soluciar problema de id repetido en la alerta, esto es un recordatorio
    ///LEER ANTES DE EMPEZAR EL LUNES
    //SE DEBEN CAMBIAR LAS LISTAS DE LOS INPUTS DE ADD TEACHER CLASS Y HACER FUNCIONAR CON SUS RESPECTIVAS CONDICIONES



    const handleCheckDayOffOnline = (checkboxN) => {
        switch (checkboxN) {
            case 0:
                setCheckDayOff(!checkDayOff)

                break;
            case 1:
                setCheckOnline(!checkOnline)

                break;

            default:
                break;
        }
    }

    //mañana realizar api para lista de salones por sucursal y ponerlos despues realizar el agregado de classes
    const handleCheckboxChange = (event, dayId) => {
        if (event.target.checked) {
            setSelectedDays([...selectedDays, dayId]); // Agrega el día seleccionado
        } else {
            setSelectedDays(selectedDays.filter(id => id !== dayId)); // Remueve el día si se desmarca
        }
    };
    // 👇 Este efecto se ejecuta cada vez que cambia `selectedDays`
    useEffect(() => {
        setDisableTimeStart(selectedDays.length === 0); // Si no hay días seleccionados, deshabilita el input
        setDisableTimeEnd(selectedDays.length === 0); // Si no hay días seleccionados, deshabilita el input

    }, [selectedDays]);
    return (
        props.open &&
        <>
            <Modal isOpen={props.open} overlayClick={false}>
                <div className='add-teacher-class'>
                    <section className="header">
                        <h2 className='title-1'>Agregar grupo</h2>
                        <div className='checks-inp'>
                            <InputAnimated type='checkbox' placeholder='Hora disponible' defaultChecked={checkDayOff} onChange={() => { handleCheckDayOffOnline(0) }}></InputAnimated>
                            {
                                !checkDayOff &&
                                <InputAnimated type='checkbox' placeholder='Clase online' defaultChecked={checkOnline} onChange={() => { handleCheckDayOffOnline(1) }}></InputAnimated>
                            }
                        </div>

                    </section>
                    <section className="content">
                        <div className='section'>

                            <Select
                                disabled={disableAll}
                                options={filterTeachers}
                                placeholder="Maestro"
                                className={'main-color ' + teacherRequire}
                                onChange={(e) => setTeacher(e.target.value)}
                                value={teacher}
                            />
                            <Select
                                disabled={disableSubsidiary || disableAll}
                                options={dataLists.subsidiaryList}
                                placeholder="Sucursal"
                                className={'main-color ' + subsidiaryRequire}
                                onChange={(e) => setSubsidiarySelected(e.target.value)}
                                value={subsidiarySelected}
                            />
                            {!checkDayOff &&
                                <>

                                    <Select
                                        options={dataLists.typeclassList}
                                        placeholder="Tipo de clase"
                                        className={'main-color ' + typeClassRequire}
                                        onChange={(e) => setTypeClassSelected(e.target.value)}
                                        value={typeClassSelected}
                                    />
                                    <Select
                                        options={ageGroupOptions}
                                        placeholder="Niños / Adultos"
                                        className={'main-color ' + agegroupRequire}
                                        onChange={(e) => { setAgeGroup(e.target.value); setLevelSelected(""); setUnitSelected("") }} // Actualizar grupo de edad
                                        value={ageGroup}
                                    />
                                    <Select
                                        options={ageGroup === 'adults' ? dataLists.levelList.adultList : dataLists.levelList.childrenList} // Opciones dinámicas basadas en el grupo de edad
                                        placeholder="Nivel"
                                        className={'main-color ' + levelRequire}
                                        onChange={(e) => { setLevelSelected(e.target.value); setUnitSelected("") }}
                                        value={levelSelected}
                                        disabled={!ageGroup ? true : false}
                                    />
                                    <Select
                                        options={filterUnits}
                                        placeholder="Unidad"
                                        className={'main-color ' + unitRequire}
                                        onChange={(e) => setUnitSelected(e.target.value)}
                                        value={unitSelected}
                                        disabled={!levelSelected ? true : false}
                                    />
                                </>
                            }

                            <div className='date-time'>
                                <h1 className='title-2'>Horario</h1>
                                <div className={'class-days ' + classDayRequire}>
                                    {dataLists.classdayList.map((day) => (
                                        <InputAnimated
                                            key={day.value}
                                            className={'main-color checkbox_days '}
                                            type='checkbox'
                                            value={day.value} // Asigna el ID del día
                                            checked={selectedDays.includes(day.value)} // Verifica si está seleccionado
                                            onChange={(e) => handleCheckboxChange(e, day.value)}
                                            placeholder={day.initial_name} // Muestra la letra del día
                                        />
                                    ))}
                                </div>
                                <div className='time-inputs'>
                                    {!checkDayOff &&
                                        <InputAnimated className={dateStartRequire} type="date" placeholder="Fecha de inicio" value={dateStartSelected} onChange={(e) => setDateStartSelected(e.target.value)} />
                                    }
                                    <InputAnimated type="time" step="1800" placeholder="Inicia" className={timeStartRequire} value={timeStart} disabled={disableTimeStart} onChange={(e) => { handleChangeTimeStart(e) }} />
                                    <InputAnimated type="time" step="1800" placeholder="Termina" className={timeEndRequire} value={timeEnd} disabled={disableTimeEnd} onChange={(e) => { handleChangeTimeEnd(e) }} />
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            {!checkDayOff &&
                                <>
                                    <Select
                                        options={dataLists.classroomList} // Opciones dinámicas basadas en CLASSROOMS
                                        placeholder="Salón"
                                        className={'main-color ' + classroomRequire}
                                        onChange={(e) => setClassroomSelected(e.target.value)}
                                        value={classroomSelected}
                                    />
                                    <div className='age-range'>
                                        <h1 className='title-2'>Rango de edad</h1>
                                        <div className='age-inputs'>
                                            <InputAnimated className={ageStartRequire} type="number" placeholder="Edad mínima" value={ageStart} onChange={(e) => setAgeStart(e.target.value)} />
                                            <InputAnimated className={ageEndRequire} type="number" placeholder="Edad máxima" value={ageEnd} onChange={(e) => setAgeEnd(e.target.value)} />
                                        </div>
                                    </div>
                                </>
                            }

                            <div className='notes'>
                                <h1 className='title-2'>Observaciones</h1>
                                <textarea className='main-color' placeholder="Escribe aquí las observaciones..." onChange={(e) => setObservations(e.target.value)} value={observations} />
                            </div>
                        </div>
                    </section>
                    <section className="footer">
                        <Button text='Cancelar' onClick={() => { props.onCloseButton(); clearVariables() }} backgroundColor='red'></Button>
                        <Button text='Guardar' onClick={() => { confirmAddTeacher() }}></Button>
                    </section>
                </div >
            </Modal >
            <div className="divAlert">
                <Alert arrayAlertsModule={arrayAlerts} setArrayAlertsModule={setArrayAlerts} isVisibleModule={isVisible} setIsVisibleModule={setIsVisible} />

            </div>
        </>



    )
}
export default ModalAddTeacherClass