import React from "react";
import "./inputAnimated.scss"
const InputAnimated = (props) => {
    return (
        <div className={props.type==='checkbox'? 'input-container-animated '+props.className :"input-container-animated"} >
            <input disabled={props.disabled} className={props.type==='checkbox'? 'checkbox':"txt-inp main-color "+props.className} type={props.type} defaultChecked={props.defaultChecked} value={props.value} onChange={props.onChange} placeholder=" " required={props.required} step={props.step} checked={props.checked}></input>
            <label className={props.type==='checkbox'? 'check-label':"input-label main-color"}>{props.placeholder}</label>
        </div>
    )
}
export default InputAnimated