import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import "../src/ClearDarkMode.scss";
import "../src/GlobalStyles.scss"
import React, { useState, useEffect } from 'react';
// Landing page
import Header from './components/header/header';
import ActiveOnline from './modules/activeOnline/activeOnline'
import Contacto from './modules/contacto/contacto'
import Equipo from './modules/equipo/equipo'
import ExamenUbicacion from './modules/ExamenUbicacion/examenUbicacion'
import Home from './modules/home/home'
import Instalaciones from './modules/instalaciones/instalaciones'
import Login from './modules/login/login'
import AvisoPrivacidad from './modules/avisoPrivacidad/avisoPrivacidad';
//RecoverPassword
import RecoverAccount from './modules/forgottenPassword/recoverAccount/recoverAccount';
import RecoverAccountCode from './modules/forgottenPassword/recoverAccountCode/recoverAccountCode';
import ChangePassword from './modules/forgottenPassword/changePassword/changePassword';
//Verification 
import EmailVerification from './modules/verification/emailVerification/emailVerification';
///// Authentication
import Authentication from './modules/authentication/authenticationMain/authentication';
import EmailAuthentication from './modules/authentication/emailAuthentication/emailAuthentication';
import PhoneAuthentication from './modules/authentication/phoneAuthentication/phoneAuthentication';
///
import Register from './modules/register/register'
import MetodoEnseñanza from './modules/metodoEza/metodoEza'
import NuestrosCursos from './modules/nuestrosCursos/nuestrosCursos'
import SobreNosotros from './modules/sobreNosotros/sobreNosotros'
import Footer from "./components/footer/footer";
import Inscribete from './modules/inscribete/inscribete';
//Error 404
import Error404 from './modules/error404/error404';
// Teacher
import HomeT from './modules/TeacherSection/home/home'
import HomeTKids from './modules/TeacherSection/homeTKids/homeTKids'
// Direccion
import Direccion from './modules/direccion/direccion';
// SuperAdmin
import SuperAdmin from './modules/superAdmin/superAdmin'
//Reception
import Reception from './modules/reception/reception';


import UserProvider from "./context/userProvider";
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

function App() {
  // State for dark mode
  const [isActiveDark, setIsActiveDark] = useState(() => {
    // Get saved theme from localStorage
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";  // Return true if "dark", otherwise false
  });

  // useEffect to update localStorage when mode changes
  useEffect(() => {
    localStorage.setItem("theme", isActiveDark ? "dark" : "light");
  }, [isActiveDark]);

  // Function to toggle dark mode
  function handleDark() {
    setIsActiveDark(!isActiveDark);  // Toggle dark mode
  }
  return (
    <>

      <Router>
        <UserProvider>
          <ScrollToTop />
          <Header />
          <Routes>
            {/* Landing Page */}
            <Route exact path='/' element={<Home />} />
            <Route exact path='/proximosCursos' element={<Inscribete />} />
            <Route exact path='/clasesEnLinea' element={<ActiveOnline />} />
            <Route exact path='/contacto' element={<Contacto />} />
            <Route exact path='/nuestroEquipo' element={<Equipo />} />
            <Route exact path='/examenDeUbicacion' element={<ExamenUbicacion />} />
            <Route exact path='/instalaciones' element={<Instalaciones />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/register' element={<Register />} />
            <Route exact path='/metodoDeEnsenanza' element={<MetodoEnseñanza />} />
            <Route exact path='/ofertaEducativa' element={<NuestrosCursos />} />
            <Route exact path='/sobreNosotros' element={<SobreNosotros />} />
            <Route exact path='/avisoPrivacidad' element={<AvisoPrivacidad />} />
            {/* Recover Account */}
            <Route exact path='/recoverAccount' element={<RecoverAccount />} />
            <Route exact path='/recoverAccountCode' element={<RecoverAccountCode />} />
            <Route exact path='/changePassword' element={<ChangePassword />} />
            {/* Email Verification */}
            <Route exact path='/emailVerification' element={<EmailVerification />} />
            {/* Authentication */}
            <Route exact path='/authentication' element={<Authentication />} />
            <Route exact path='/emailAuthentication' element={<EmailAuthentication />} />
            <Route exact path='/phoneAuthentication' element={<PhoneAuthentication />} />
            <Route exact path='*' element={<Error404 />} />


            {/* Teache Section */}
            <Route exact path='/HomeT' element={<HomeT />} />
            <Route exact path='/HomeTkids' element={<HomeTKids />} />
            {/* Direccion */}
            <Route exact path='/direccion' element={<div className={`App ${isActiveDark ? "dark" : "light"}`}>
              <Direccion />
            </div>} />

            {/* Super Admin */}
            <Route exact path='/superAdmin' element={<SuperAdmin />} />
            {/* Reception */}
            <Route exact path='/reception' element={
              <div className={`App ${isActiveDark ? "dark" : "light"}`}>
                <Reception />
              </div>

            }
            />
            {/* Error 404 */}
          </Routes>
          <Footer />
        </UserProvider>
      </Router>
    </>
  );
}

export default App;
