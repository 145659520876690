import React, { useState } from "react";
import "./inputPassword.scss";
const InputPassword = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="input-container-password">
      <input maxLength={props.maxLength} onInput={props.onInput} disabled={props.disabled} className={props.className} type={!isVisible ? "password" : "text"}
        value={props.value} onChange={props.onChange} placeholder=" " required={props.required} onKeyDown={props.onKeyDown}></input>
      <label className="input-label">{props.placeholder}</label>
      <i className="material-icons visibility" onClick={handleVisibility}>
        {!isVisible ? "visibility_off" : "visibility"}
      </i>
    </div>
  );
};
export default InputPassword;
